<template>
  <index-page-title>
    <template #title>
      <i18n path="domains.domains.pages.consultation.title">
        <br />
        <br class="xl:hidden" />
      </i18n>
    </template>

    <template #description>
      <i18n path="domains.domains.pages.consultation.description">
        <br />
        <br class="xl:hidden" />
      </i18n>
    </template>

    <template #buttons>
      <div class="xl:flex xl:flex-col xl:items-start">
        <nuxt-link
          class="flex items-center bg-[#FF2069] text-base font-medium text-v-ui-root-monochrome-0 h-9 px-4 rounded-full whitespace-nowrap xl:h-12 xl:text-xl"
          :to="{ name: 'dashboard', query: { to: 'duty' } }"
        >
          {{ $t('domains.domains.pages.consultation.question-doctor') }}
        </nuxt-link>
        <nuxt-link
          class="xl:mt-4 mt-2 flex items-center text-base font-medium text-[#5B3AEF] h-9 px-4 rounded-full border-2 border-[#5B3AEF] whitespace-nowrap xl:h-12 xl:text-xl"
          :to="{ name: 'dashboard' }"
        >
          {{
            $t('domains.domains.pages.consultation.consultation-appointment')
          }}
        </nuxt-link>
      </div>
    </template>
  </index-page-title>
</template>

<script>
import IndexPageTitle from '../index-page-title/IndexPageTitle';
export default {
  name: 'ConsultationTitle',
  components: { IndexPageTitle }
};
</script>
